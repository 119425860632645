import React, { useEffect, useState } from "react";
import { Link , useNavigate} from "react-router-dom";

import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";

import {post} from '../../helpers/api_helper' 
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';
 
import {
  Badge,
  Col,
  Container,
  FormGroup,
  InputGroup,
  CardHeader,
  Row,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  UncontrolledTooltip,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Spinners from "../../components/Common/Spinner";
import { ToastContainer } from "react-toastify"; 

const Withdrawal = (props) => {
  //meta title
  document.title=props.t("Withdrawal")+" | RIDDER TITAN";

  const navigate = useNavigate();
 
  const [isLoading, setLoading] = useState(false) 
  const [isDisabled, setIsDisabled] = useState(true);
  const [ amount, setAmount] = useState(0);   
  const [ password, setPassword] = useState('');   
  const [ wallettype, setWallettype] = useState('USDT');     
  const [ walletaddress, setWalletaddress] = useState('');     
  const [ withdrawal_walet, setWithdrawal_walet] = useState([]);     
  const [ min_withdrawal, setMin_withdrawal ] = useState(0);   
  const [ withdrawal_fee, setWithdrawal_fee ] = useState(0);   
  const [ amount_receive, setAmount_receive ] = useState(0);   
  const [ withdrawal_fee_pal, setWithdrawal_fee_pal ] = useState(0);   
  const [ total_cash, setTotal_cash ] = useState(0);   
  const [ showPs, setShowPs] = useState(false);
 
  const userProfile = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser")); 
      const response = await post("/user/profile", {token: obj.token});
 
      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else { 
        let userdata = response.data.data;
          
        if(userdata.set_withdraw_password == true){
          navigate('/profile'); 
        }
        setTotal_cash(userdata.total_cash);    
        setMin_withdrawal(userdata.min_withdrawal);    
        setWithdrawal_fee_pal(userdata.withdrawal_fee_pal);    
        setWithdrawal_walet(userdata.withdrawal_walet);   
        // setWallets(data.wallets);   
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    userProfile(); // Call the async function immediately
  }, []); // Empty dependency array to run the effect only once
   
  const toggleShowPs = () => {
    setShowPs((showPs) => !showPs); 
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      amount:0,
      password:'',
      walletaddress:'',
      wallettype:'USDT'
    },
    validationSchema: Yup.object({ 
      amount: Yup.number()
      .typeError(props.t("Amount must be a number"))
      .min(0, props.t("Amount must be greater than or equal to 0"))
      .required(props.t("Please Enter Amount")), 
      password: Yup.string().required(props.t("Please Enter Your Trading Password")),  
      walletaddress: Yup.string().required(props.t("Please Enter Wallet Address")),
      wallettype: Yup.string().required(props.t("Please Select Wallet Type"))
    }), 
    onSubmit: async (values) => { 
      try {  
        const obj = JSON.parse(localStorage.getItem("authUser")); 
        values.token = obj.token; 
      
        const response = await post("/user/postWithdraw", values); 
  
        if (response.data.code === 3002) {
          navigate('/logout');  
        }

        if(response.data.code !== 1){

          if(response.data.data?.message){
            let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
            Notify.failure(message); 
          } else { 
            Notify.failure(props.t(response.data.message)); 
          } 

        } else { 
          Notify.success(props.t(response.data.message)); 
          
          navigate('/withdrawal-list'); 
        }
      } catch (error) {  
        console.log(error)
        // Notify.failure(error.data.message); 
      }
    }, 
  });
  
  useEffect(() => {
 
      if(validation.values.amount <= 0 || validation.values.password === '' || validation.values.walletaddress === ''){
        setIsDisabled(true) 
      } else {
        setIsDisabled(false) 
      }
      let final_amount = validation.values.amount * (1 - withdrawal_fee_pal/100);
      final_amount = parseFloat(final_amount).toFixed(2)
      setAmount_receive(final_amount)  
  }, [validation.values]);
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        
          <Row className="justify-content-center">
            <Col>
              <Card>
                <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0 card-title flex-grow-1">{props.t("Withdrawal")}</h5>
                        <div className="flex-shrink-0">
                            <Link to="/withdrawal-list"className="btn btn-primary me-1">{props.t("Withdrawal Records")}</Link> 
                        </div>
                    </div>
                </CardBody> 
 
                <CardBody>
                {
                  isLoading ? <Spinners setLoading={setLoading} />
                    : 
                   
                    <div className="withdrawal-box">
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          > 
                            <Row> 
                              <Col lg="12"> 
                                <FormGroup className="mb-3">
                                  <label>
                                    {props.t("Enter Withdraw Amount")}
                                    <small>({props.t("total_cash")}:<span className="font-size-11 badge-soft-success badge bg-secondary">${total_cash}</span>)</small>
                                  </label>
                                  <InputGroup className="input-group-alternative mb-3">  
                                    <Input 
                                      name="amount" 
                                      type="number"    
                                      required 
                                      min="1" 
                                      max={total_cash} 
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.amount || 0}
                                    />
                                    {validation.touched.amount && validation.errors.amount ? (
                                      <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                    ) : null}
                                  </InputGroup>
                                </FormGroup>
                              </Col>  
                            </Row>  
                            <Row> 
                              <Col lg="12"> 
                                <FormGroup className="mb-3">
                                  <label>{props.t("Withdrawal Fee")}({withdrawal_fee_pal}%)</label>
                                  <InputGroup className="input-group-alternative mb-3"> 
                                    <Input   
                                      value={withdrawal_fee_pal}
                                      disabled={true} 
                                    />
                                  </InputGroup>
                                </FormGroup> 
                              </Col>  
                            </Row>  
                            <Row> 
                              <Col lg="12"> 
                                <FormGroup className="mb-3">
                                  <label>{props.t("Amount Receive")}</label>
                                  <InputGroup className="input-group-alternative mb-3 disabled">  
                                    <Input 
                                      value={amount_receive}
                                      disabled={true} 
                                    />
                                  </InputGroup>
                                </FormGroup> 
                              </Col>  
                            </Row>  
                            <Row> 
                              <Col lg="12"> 
                                <FormGroup className="mb-3">
                                    <label>{props.t("Wallet Type")}</label>
                                    <select
                                      name="wallettype"
                                      className="form-select"
                                      id="wallettype-input"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.wallettype} // Set the value attribute
                                    >
                                    {withdrawal_walet.map((type, key) => (
                                      <option key={key} value={type}>{type}</option>
                                    ))}
                                    </select> 
                                </FormGroup> 
                              </Col>  
                            </Row>  
                            <Row> 
                              <Col lg="12"> 
                                <FormGroup className="mb-3">
                                  <label>{props.t("Wallet Address")}</label>
                                  <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                      placeholder={props.t("Wallet Address")}  
                                      type="text"
                                      name="walletaddress"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.walletaddress || ""}
                                    />
                                    {validation.touched.walletaddress && validation.errors.walletaddress ? (
                                      <FormFeedback type="invalid">{validation.errors.walletaddress}</FormFeedback>
                                    ) : null}
                                  </InputGroup>
                                </FormGroup> 
                              </Col>  
                            </Row>  
                            <Row> 
                              <Col lg="12"> 
                                <FormGroup className="mb-3">
                                  <label>{props.t("Trading Password")}</label>
                                  <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                      placeholder={props.t("Trading Password")}  
                                      type="password"
                                      name="password" 
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.password || ""}
                                    />
                                    <div className="input-group-append" onClick={toggleShowPs}> 
                                        <span className="input-group-text">
                                          {showPs ?(
                                                <i className="fas fa-eye"></i>
                                            ): (
                                              <i className="fas fa-eye-slash"></i> 
                                          )} 
                                        </span>
                                    </div> 
                                  </InputGroup>
                                    {validation.touched.password && validation.errors.password ? (
                                      <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                    ) : null} 
                                    
                                </FormGroup>
                              </Col>  
                            </Row>   
                            <div className="row justify-content-center"> 
                              <Col className="row justify-content-center"> 
                                  <button
                                    className="btn btn-primary btn-block " 
                                    disabled={isDisabled}  
                                    type="submit"
                                    >
                                    {props.t("Submit")}
                                  </button> 
                              </Col> 
                            </div>  
                        </Form> 
                    </div>
                }
                </CardBody>
              </Card>
            </Col>
          </Row>
 
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}; 
export default withTranslation()(Withdrawal);

Withdrawal.propTypes = {
  t: PropTypes.any
};