import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const Line = ({dataColors, marketdata}) => {
  const lineEChartColors = getChartColorsArray(dataColors);
 
  const options = {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      zlevel: 0,
      x: 50,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
    },
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu"],
      axisLine: {
        lineStyle: {
          color: "#8791af",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#8791af"
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(166, 176, 207, 0.1)"
        }
      },
      axisLabel: {
        formatter: function (value) {
          // Format the y-axis label to display 3 decimal places
          return value.toFixed(3);
        }
      },
      min: function(value) {
        // Round down the minimum value to the nearest 0.001
        return (Math.floor(value.min * 1000) / 1000 ) - 0.1;
      },
      max: function(value) {
        // Round up the maximum value to the nearest 0.001
        return 0.1 + Math.ceil(value.max * 1000) / 1000;
      }
    },
    series: [
      {
        data: [0.200, 0.201, 0.202, 0.203],
        type: "line",
      },
    ],
    color: lineEChartColors,
    textStyle: {
      color: ["#8791af"],
    },
  };

  const datetimeData = marketdata.map(item => item.datetime);
  const priceData = marketdata.map(item => parseFloat(item.price)); // Assuming prices are numerical

  // Assigning data to xAxis and series
  options.xAxis.data = datetimeData;
  options.series[0].data = priceData;

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};
export default Line;
