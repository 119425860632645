import React, { Component } from 'react';
import axios from 'axios';
import Pages500 from "./pages/Utility/pages-500";

const sendErrorToTelegram = (errorMessage, componentStack, url) => {
  const botId = "6938150680:AAHcT9hw-Y_M7Or9Quwi3xm45hi9v6jqDCw"
  const chatId = "-4246155010"
  const apiurl = `https://api.telegram.org/bot${botId}/sendMessage`;
  const env =  process.env.REACT_APP_ENV; 
  const data = {
    chat_id: chatId,
    text: `[${env}] ${errorMessage}\n\nComponent Stack:\n${componentStack}\n\nCurrent URL: ${url}`,

  };
  if(env === "dev") return;
  
  axios.post(apiurl, data)
    .catch((error) => {
      console.error(`error: ${error.message}`);
    });
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
     // Send error message to Telegram
    const currentUrl = window.location.href;
     sendErrorToTelegram(`An error occurred: ${error.message}`, errorInfo.componentStack, currentUrl);

    // Set state with error and error info
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <Pages500 />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;