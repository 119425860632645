import PropTypes from "prop-types";
import React, { useState, useEffect  } from "react"; 
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

 
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
 
import { withTranslation } from "react-i18next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect"; 
import withRouter from "components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {post} from '../../helpers/api_helper'  
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';
  
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

const PasswordReset1 = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email, token}  = useParams(); 
 
  document.title = props.t("Reset Password")+" | RIDDER TITAN.";
 
  const [username, setUsername] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    
    if(obj?.token){
      navigate('/profile'); 
    }
    
  }, []); // Empty dependency array to run the effect only once

  useEffect(() => {  
    if (!email || !token) { 
      navigate('/page-not-found');
    }  
  }, [email, token, navigate]);

  useEffect(() => {
    if (email && token) {
      verifyPasswordCode(email, token);
    }
  }, [email, token]);


  const validation  = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: { 
      password: '',
      password_confirm: '' 
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(props.t("Please Enter Password")),
      password_confirm:  Yup
      .string()
      .oneOf([Yup.ref('password')], props.t("Passwords must match")), 
    }),
    onSubmit: async (values) => { 
      try {   
        values.email = email; 
        values.token = token; 
     
        const response = await post("/user/resetPassword", values); 
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if(response.data.code !== 1){ 
          Notify.failure(response.data.message); 
        } else { 

          
          if(response.data.data?.message){
            let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
            Notify.success(message); 
            
            navigate('/transfer-list'); 
  
          } else { 
            Notify.success(props.t(response.data.message)); 
          }  
        }
      } catch (error) {  
        console.log(error)
        // Notify.error(error.data.message); 
      }
    }
  });

  const verifyPasswordCode = async (email, token) => { 
    let formData = {
      email: email,
      token: token
    }; 

    try {
      const response = await post("/user/verifyPasswordCode", formData);  
      if(response.data.code !== 1){ 
        if(response.data.data?.message){
          let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
          Notify.failure(message); 
        } else { 
          Notify.failure(props.t(response.data.message)); 
        }
      } else {  
        if(response.data.data.isValid === true){ 
          setIsValid(true);
          setUsername(response.data.data.username);
        } else {
          setIsValid(false);
        }
      }
    } catch (error) {  
      console.log(error);
      Notify.error(props.t("An error occurred while sending the request.")); 
    }
  }


  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{props.t("Reset Password")}</h5>
                        <p>{props.t("Reset Password with RIDDER TITAN.")}</p> 
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-between">
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span> 
                      </div>
                    </Link>
                    <div className="nav-lang">
                      <LanguageDropdown />
                    </div>
                  </div> 
                  <div className="p-2">  
                   {isValid ? (
                      <div>
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Email")}</Label>
                            <Input
                              name="email"
                              className="form-control" 
                              type="email" 
                              value={email} 
                              disabled={true}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Username")}</Label>
                            <Input 
                              className="form-control"
                              type="text"
                              value={username}
                              disabled={true}
                            />
                          </div>
                          <div className="mb-3">
                          <Label className="form-label">{props.t("Password")}</Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder={props.t("Enter Password")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Password Confirmation")}</Label>
                            <Input
                              name="password_confirm"
                              type="password"
                              placeholder={props.t("Enter Password Confirmation")}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password_confirm || ""}
                              invalid={
                                validation.touched.password_confirm && validation.errors.password_confirm ? true : false
                              }
                            />
                            {validation.touched.password_confirm && validation.errors.password_confirm ? (
                              <FormFeedback type="invalid">{validation.errors.password_confirm}</FormFeedback>
                            ) : null}
                          </div>
                          
                          <Row className="mb-3">
                            <Col className="text-end">
                              <button
                                className="btn btn-primary w-md "
                                type="submit"
                              >
                                {props.t("Submit")}
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                      ) : (
                        <>
                        <h4>{props.t("invalid verification code")}</h4>  
                        <Link
                          className="btn btn-primary waves-effect waves-light "
                          to="/"
                          >
                          {props.t("Back to home")}
                        </Link>
                        </>
                      )} 
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center"> 
                <p>
                  © {new Date().getFullYear()} RIDDER TITAN
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

PasswordReset1.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any
};
 
export default withTranslation()(withRouter(PasswordReset1));
 