import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Form,
  FormGroup,
  FormFeedback
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import {post} from '../../helpers/api_helper' 
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';
  
//Dropzone
import Dropzone from "react-dropzone";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { withTranslation } from "react-i18next";
 
const DepositForm = props => {

  //meta title
  document.title=props.t("Deposit")+" | RIDDER TITAN";
 
  const navigate = useNavigate(); 
  const [selectedFiles, setSelectedFiles] = useState([]); 
  const [base64Images, setBase64Images] = useState([]);
  const [canSubmit, setCanSubmit] = useState(false); 
  const [livechat, setLivechat] = useState("");

  const MAX_IMAGES = 20; // Change this to the desired maximum number of images

  useEffect(() => {  
    const userProfile = async () => {
      try {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const response = await post("/user/profile", {token: obj.token});
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if (response.data.code !== 1) {
          Notify.failure(props.t(response.data.message)); 
        } else {
          let userdata = response.data.data;
          setLivechat(userdata.live_chat);
       
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    userProfile(); // Call the async function immediately
  }, []); // Empty dependency array to run the effect only once

  const handleRemoveImage = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setBase64Images(prevImages => prevImages.filter((_, i) => i !== index));
  };
  const handleAcceptedFiles = async (files) => {
    if (selectedFiles.length + files.length > MAX_IMAGES) { 
      let message = parseMessage(props.t(`You can only upload %count images.`), {count:MAX_IMAGES});
      Notify.failure(message); 
      return;
    }

    const formattedFiles = files.map(file => ({
      ...file,
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    }));
  
    // Append the newly selected files to the existing ones
    setSelectedFiles(prevFiles => [...prevFiles, ...formattedFiles]);
    
    const readers = files.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    });
  
    const base64Images = await Promise.all(readers);
    setBase64Images(prevImages => [...prevImages, ...base64Images]);
 
  };
 
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      amount: 0
    },
    validationSchema: Yup.object({
      amount: Yup.number()
          .required(props.t("Amount is required")) 
  }),
    onSubmit: async (values) => { 
   
      try {  
        const obj = JSON.parse(localStorage.getItem("authUser"));
        values.upload = base64Images;
        values.token = obj.token;
      
        const response = await post("/user/postDeposit", values); 
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if(response.data.code !== 1){ 
          let message =  props.t(response.data.message)
          Notify.failure(message); 
        } else { 
          Notify.success(response.data.message); 
          navigate('/deposit-list'); 
        }
      } catch (error) {  
        console.log(error)
        // Notify.error(error.data.message); 
      }
    }, 
  });

  useEffect(() => { 
    if (
      validation.values.amount && 
      base64Images.length > 0
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    } 
  }, [validation.values, base64Images]);
  
  const openLiveChat = () => { 
    window.open(livechat, '_blank'); 
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
       
          <Row className="justify-content-center">
            <Col >
              <Card> 
                <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0 card-title flex-grow-1">{props.t("Deposit")}</h5>
                        <div className="flex-shrink-0">
                            <Link to="/deposit-list"className="btn btn-primary me-1">{props.t("Deposit Records")}</Link> 
                        </div>
                    </div>
                </CardBody>
 
                <CardBody> 
                    <div className="justify-content-start"> 
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                        <div className=" clearfix">  
                          <Row> 
                            <Col lg="12">
                              <FormGroup className="mb-3">
                                <Label
                                  htmlFor="kycfirstname-input"
                                  className="form-label"
                                >
                                  {props.t("USDT Address")}
                                </Label>
                                <div> 
                                  <button
                                    onClick={() => openLiveChat()}
                                    className="btn btn-primary btn-block "
                                    >
                                    {props.t("Chat with us")}
                                  </button>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup className="mb-3">
                                <Label
                                  htmlFor="kycfirstname-input"
                                  className="form-label"
                                >
                                {props.t("Amount")}
                                </Label>
                                <Input
                                  name="amount"
                                  className="form-control"
                                  type="number" 
                                  min={0}
                                  id="amount-input"
                                  placeholder={props.t("Enter Amount")}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.amount || ""}
                                />
                                {validation.touched.amount && validation.errors.amount ? (
                                  <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col> 
                          </Row>  
                          <Row>
                            <Col lg="12">
                              <h5 className="font-size-14 mb-3">
                              {props.t("Upload pay slip")}
                              </h5>
                              <div className="pay-doc-verification mb-3">
                                <Dropzone
                                  onDrop={acceptedFiles =>
                                    handleAcceptedFiles(acceptedFiles)
                                  }
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                      <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                          <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                        </div>
                                        <h4>{props.t("Drop files here or click to upload.")}</h4>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto uploadImg">
                                              <span className="btn-close-prev" onClick={() => handleRemoveImage(i)}><i className="fas fa-times"></i></span> 
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {f.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    );
                                  })}
                                </div>
                              </div>
                            </Col>
                          </Row>   
                          <div className="row justify-content-center"> 
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                                disabled={!canSubmit}
                                >
                                  {props.t("Submit")}
                              </button> 
                          </div>  
                        </div>
                        </Form>
                    </div> 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
 
export default withTranslation()(DepositForm);

DepositForm.propTypes = {
  t: PropTypes.any
};