import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  InputGroup,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  FormFeedback
} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import "react-datepicker/dist/react-datepicker.css";
import classnames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";


import { post } from '../../helpers/api_helper' 
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';

//Dropzone
import Dropzone from "react-dropzone";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import images
import verificationImg from "../../assets/images/verification-img.png";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
 

const Sell = props => {

  //meta title
  document.title=props.t("Sell Peferred Share")+" | RIDDER TITAN";
 
  const navigate = useNavigate();  
  const [canSubmit, setCanSubmit] = useState(false);  

  useEffect(() => {
    const userProfile = async () => {
      try {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const response = await post("/user/profile", {token: obj.token});
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if (response.data.code !== 1) {
          Notify.failure(props.t(response.data.message)); 
        } else {
          let userdata = response.data.data;
          
          if(userdata.set_withdraw_password == true){
            navigate('/profile'); 
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    userProfile(); // Call the async function immediately
  
  }, []); // Empty dependency array to run the effect only once
 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: { 
      qty: '',
      price: '',
      trading_password: ''
    },
    validationSchema: Yup.object({ 
      qty: Yup.number()
      .typeError(props.t("Qty must be a number"))
      .min(0, props.t("Qty must be greater than or equal to 0"))
      .required(props.t("Please Enter Qty")), 
      price: Yup.number()
      .typeError(props.t("Stock price must be a number"))
      .min(0, props.t("Stock price must be greater than or equal to 0"))
      .required(props.t("Please Enter Stock price")), 
      trading_password: Yup.string().required(props.t("Please Enter Your Trading Password")),  
    }), 
    onSubmit: async (values) => { 
      try {  
        const obj = JSON.parse(localStorage.getItem("authUser")); 
        values.token = obj.token;
        values.wallet_type = "total_reg";
        const response = await post("/trade/sell", values); 
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if(response.data.code !== 1){ 
          Notify.failure(props.t(response.data.message)); 
        } else { 
          let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
          Notify.success(message); 
          
          navigate('/sell-list'); 
        }
      } catch (error) {  
        consoleLog(error) 
      }
    }, 
  });
 
  useEffect(() => { 
    if (
      validation.values.price &&
      validation.values.qty &&
      validation.values.trading_password && 
      validation.values.qty > 0 &&
      validation.values.price > 0
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    } 
  }, [validation.values]);
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("Sell Peferred Share")} breadcrumbItem={props.t("Sell Peferred Share")} />

          <Row className="justify-content-center mt-lg-5">
            <Col xl="5" sm="9">
              <Card>
                <CardBody>
                    <div className="text-center">
                      <Row>
                        <Col lg="12">
                          <h4 className="mt-4 fw-semibold">
                          {props.t("Sell Peferred Share")}
                          </h4> 
                        </Col>
                      </Row> 
                    </div> 

                    <div className="justify-content-start"> 
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                        <div id="kyc-verify-wizard" className="wizard clearfix"> 
                          <div className="content clearfix"> 
                            <Row>  
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="qty-input"
                                    className="form-label"
                                  >
                                    {props.t("Qty")}
                                  </Label>
                                  <Input
                                    name="qty"
                                    type="number"
                                    className="form-control"
                                    id="qty-input"
                                    placeholder={props.t("Enter Qty")}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.qty || ""}
                                  />
                                  {validation.touched.qty && validation.errors.qty ? (
                                    <FormFeedback type="invalid">{validation.errors.qty}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row> 
                            <Row>  
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="price-input"
                                    className="form-label"
                                  >
                                    {props.t("Stock Price")}
                                  </Label>
                                  <Input
                                    name="price"
                                    type="number"
                                    className="form-control"
                                    id="price-input"
                                    placeholder={props.t("Enter Stock Price")}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.price || ""}
                                  />
                                  {validation.touched.price && validation.errors.price ? (
                                    <FormFeedback type="invalid">{validation.errors.price}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row> 
                            <Row>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="trading_password-input"
                                    className="form-label"
                                  >
                                    {props.t("Trading Password")}
                                  </Label>
                                  <Input
                                    name="trading_password"
                                    type="password"
                                    className="form-control"
                                    id="trading_password-input"
                                    placeholder={props.t("Enter Trading Password")}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.trading_password || ""}
                                  />
                                  {validation.touched.trading_password && validation.errors.trading_password ? (
                                    <FormFeedback type="invalid">{validation.errors.trading_password}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col> 
                            </Row>
                            
                          </div>

                          <div className="row justify-content-center"> 
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                                disabled={!canSubmit}
                                >
                                {props.t("Submit")}
                              </button> 
                          </div>  
                        </div>
                        </Form>
                    </div> 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}; 
export default withTranslation()(Sell);

Sell.propTypes = {
  t: PropTypes.any
};