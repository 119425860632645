import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';

import {post} from '../../helpers/api_helper' 

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { Link, useNavigate, useParams } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png"; 
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";


const Register = props => {


  const { inviteCode } = useParams(); 
  const [ invite_code, setInvite_code] = useState(inviteCode || '');
  const [ position, setPosition] = useState('L');
  const isReadOnly = inviteCode !== undefined; 
  
    const checkInviteCode = (code) => { 
      if (code.length <= 5) {
        return 'L';
      } 
      const firstThreeChars = code.substring(0, 3);

      if (firstThreeChars === 'LLL') { 
        return 'L';
      } else if (firstThreeChars === 'RRR') { 
        return 'R';
      } else {
        return 'invalid';
      }
    };

    useEffect(() => {
      if (inviteCode !== '' && inviteCode !== undefined) { // Add this check
        let result = checkInviteCode(inviteCode); 
        if (result === "L") {
          setPosition("L");
        } else if (result === "R") {
          setPosition("R");
        }
      }
    }, []);
      

  //meta title
  document.title = props.t("Register")+" | RIDDER TITAN";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
      pwdConfirm: '',
      invite_code: invite_code,
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Please Enter Your Email")),
      username: Yup.string().required(props.t("Please Enter Your Username")),
      password: Yup.string().required(props.t("Please Enter Your Password")),
      pwdConfirm: Yup.string().required(props.t("Please Enter Your Password Confirmation")),
      invite_code: Yup.string().required(props.t("Please Enter Invite Code")),
    }),
    onSubmit: async (values) => { 
      try { 
        const response = await post("/user/register", values); 
  
        if(response.data.code !== 1){ 
          Notify.failure(props.t(response.data.message)); 
        } else {
          localStorage.setItem("authUser", JSON.stringify(response.data.data));
     
          navigate('/dashboard'); 
        }
      } catch (error) {  
        console.log(error)
        // Notify.failure(error.data.message); 
      }
    }
  });


  const AccountProperties = createSelector(
    (state) => state.Account,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success
      // loading: account.loading,
    })
  );

  const {
    user,
    registrationError, success
    // loading
  } = useSelector(AccountProperties);

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  useEffect(() => {
    success && setTimeout(() => navigate("/login"), 2000)
  }, [success])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{props.t("Register")}</h5>
                        <p>{props.t("Get your RIDDER TITAN account now.")}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-between">
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span> 
                      </div>
                    </Link>
                    <div className="nav-lang">
                      <LanguageDropdown />
                    </div>
                  </div>
              
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          {props.t("Register User Successfully")}
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">{props.t("Email")}</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder={props.t("Enter email")}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{props.t("Username")}</Label>
                        <Input
                          name="username"
                          type="text"
                          placeholder={props.t("Enter Username")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Password")}</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder={props.t("Enter Password")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Password Confirmation")}</Label>
                        <Input
                          name="pwdConfirm"
                          type="password"
                          placeholder={props.t("Enter Password Confirmation")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.pwdConfirm || ""}
                          invalid={
                            validation.touched.pwdConfirm && validation.errors.pwdConfirm ? true : false
                          }
                        />
                        {validation.touched.pwdConfirm && validation.errors.pwdConfirm ? (
                          <FormFeedback type="invalid">{validation.errors.pwdConfirm}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{props.t("Position")}</Label>
                        <Input 
                          type="text" 
                          value={props.t(position)} 
                          readOnly={true}
                          disabled={true}     
                        /> 
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Invite Code")}</Label>
                        <Input
                          name="invite_code"
                          type="text"
                          placeholder={props.t("Enter Invite Code")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.invite_code || invite_code} 
                          readOnly={isReadOnly}
                          disabled={isReadOnly}    

                          invalid={
                            validation.touched.invite_code && validation.errors.invite_code ? true : false
                          }
                        />
                        {validation.touched.invite_code && validation.errors.invite_code ? (
                          <FormFeedback type="invalid">{validation.errors.invite_code}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          {props.t("Register")}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                        {props.t("By registering you agree to the RIDDER TITAN.")}{" "}
                          <Link to="#" className="text-primary">
                          {props.t("Terms of Use")}
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                {props.t("Already have an account ?")}{" "} <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    {props.t("Login")}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} RIDDER TITAN
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
 
export default withTranslation()(Register);

Register.propTypes = {
  t: PropTypes.any
};
