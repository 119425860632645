// useDefaultPropsWarningSuppression.ts

import React, { useEffect } from 'react';

const useDefaultPropsWarningSuppression = () => {
  useEffect(() => {
    const originalConsoleError = console.error;
    console.error = (...args) => {
      if (typeof args[0] === 'string' && /defaultProps/.test(args[0])) {
        return; // Ignore the warning
      }
      originalConsoleError(...args);
    };

    return () => {
      console.error = originalConsoleError;
    };
  }, []);
};

export default useDefaultPropsWarningSuppression;
