import React, { useEffect, useState } from "react";
import { Link , useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import classnames from "classnames"
 
import { withTranslation } from "react-i18next";

import {post} from '../../helpers/api_helper' 


import {
  Col,
  Container,
  Row,
  Input,
  Card,
  CardBody,
  NavItem, NavLink, 
} from "reactstrap";
 
//redux
import { useSelector, useDispatch } from "react-redux";

import Spinners from "../../components/Common/Spinner";
import { ToastContainer } from "react-toastify";

import {  
  InputGroup,
  Nav, TabContent, TabPane,
} from "reactstrap";

import QRCode from 'qrcode.react';

import { Notify } from 'notiflix/build/notiflix-notify-aio';

 
const Invite = (props) => {
  //meta title
  document.title = props.t("Invite Friend")+" | RIDDER TITAN";

  const [isLoading, setLoading] = useState(false)
 
  const [ leftInviteCode, setLeftInviteCode ] = useState("");  
  const [ rightInviteCode, setRightInviteCode ] = useState("");  
  const [ leftInviteUrl, setLeftInviteUrl ] = useState("");  
  const [ rightInviteUrl, setRightInviteUrl ] = useState("");  

  const userProfile = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser")); 
      const response = await post("/user/profile", {token: obj.token});
 
      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        let data = response.data.data;
        setLeftInviteCode("LLL"+data.inviteCode); 
        setRightInviteCode("RRR"+data.inviteCode); 
                
        const siteUrl = process.env.REACT_APP_APPS_URL; 
        let left_url = siteUrl+"/register/LLL"+data.inviteCode 
        let right_url = siteUrl+"/register/RRR"+data.inviteCode 
        setLeftInviteUrl(left_url);  
        setRightInviteUrl(right_url);  

      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    userProfile(); // Call the async function immediately
  }, []); // Empty dependency array to run the effect only once
    
  const copyToClipboard  = (inviteUrl) => { 
    // Create a new text area element
    const textArea = document.createElement('textarea');
    textArea.value = inviteUrl;

    // Append the text area to the DOM
    document.body.appendChild(textArea);

    // Select and copy the text from the text area
    textArea.select();
    document.execCommand('copy');

    // Clean up
    document.body.removeChild(textArea);

    Notify.success(props.t("Invite url have copy to clipboard"));   
  }; 
  

  const [activeTab, setactiveTab] = useState("1");
  const [activeTab1, setactiveTab1] = useState("5");
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const [isMenu, setIsMenu] = useState(false)
  const [activeactionTab, setActiveactionTab] = useState("2")

  const toggleTab = tab => {
    if (toggleTab !== tab) {
      setActiveactionTab(tab)
    }
  }
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0 card-title flex-grow-1">{props.t("Invite Friend")}</h5>
                        <div className="flex-shrink-0"> 
                        </div>
                    </div>
                </CardBody>
                <CardBody>
                {
                  isLoading ? <Spinners setLoading={setLoading} />
                    :  
                  <>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "1", })} onClick={() => { toggleCustom("1"); }}>
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">{props.t("Left Area")}</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "2", })} onClick={() => { toggleCustom("2"); }}>
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">{props.t("Right Area")}</span>
                        </NavLink>
                      </NavItem> 
                    </Nav>

                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">   
                        <div className="invite-box pt-3">
                              <h4 className="mb-3">{props.t("Invite friend to join our team")}</h4>
                              <QRCode value={leftInviteUrl} />
                              <h4>{leftInviteCode}</h4>   
                              <div className="form-group mt-3">   
                                <InputGroup>
                                    <Input
                                        placeholder={props.t("Referral Url")}
                                        type="text" 
                                        value={leftInviteUrl}
                                        disabled={true} 
                                      />
                                    <div className="input-group-append" onClick={() => copyToClipboard(leftInviteUrl)}>
                                        <span className="input-group-text">
                                          <i className="far fa-copy"></i>
                                        </span>
                                    </div>
                                </InputGroup>  
                              </div>  
                            </div>
                      </TabPane>
                      <TabPane tabId="2">   
                        <div className="invite-box pt-3">
                              <h4 className="mb-3">{props.t("Invite friend to join our team")}</h4>
                              <QRCode value={rightInviteUrl} />
                              <h4>{rightInviteCode}</h4>   
                              <div className="form-group mt-3">   
                                <InputGroup>
                                    <Input
                                        placeholder={props.t("Referral Url")}
                                        type="text" 
                                        value={rightInviteUrl}
                                        disabled={true} 
                                      />
                                      <div className="input-group-append" onClick={() => copyToClipboard(rightInviteUrl)}>
                                        <span className="input-group-text">
                                          <i className="far fa-copy"></i>
                                        </span>
                                    </div>
                                </InputGroup>  
                              </div>  
                            </div>
                      </TabPane> 
                    </TabContent>
                  </>
                }
                </CardBody>
              </Card>
            </Col>
          </Row>
 
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};
 
export default withTranslation()(Invite);

Invite.propTypes = {
  t: PropTypes.any
};