import PropTypes from "prop-types"; 
import React, { useCallback, useEffect, useMemo, useState } from "react";


import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

import {post} from '../../helpers/api_helper'  
 
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { parseMessage, consoleLog } from '../../helpers/Utils'  
 
// actions
import { loginUser, socialLogin } from "../../store/actions";

import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import lightlogo from "../../assets/images/logo.png";
 
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

import { logoutUser } from "../../store/actions";

const Login = props => {

  const navigate = useNavigate();
  const history = useNavigate();
  //meta title
  document.title = props.t("Login")+" | RIDDER TITAN.";

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser(history));
  }, [dispatch, history]);

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(props.t("Please Enter Your Username")),
      password: Yup.string().required(props.t("Please Enter Your Password")),
    }), 
    onSubmit: async (values) => {
      try { 
        const response = await post("/user/login", values); 

        if(response.data.code !== 1){ 
          Notify.failure(props.t(response.data.message)); 
        } else {
          
          localStorage.setItem("authUser", JSON.stringify(response.data.data));
     
          let message = parseMessage(props.t(`Success. Welcome %username`), {username:response.data.data.username});
          Notify.success(message); 
          
          navigate('/dashboard'); 
        }
      } catch (error) {  
        console.log(error)
        // Notify.error(error.data.message); 
      }
    },
  });


  const LoginProperties = createSelector(
    (state) => state.Login,
    (login) => ({
      error: login.error
    })
  );

  const {
    error
  } = useSelector(LoginProperties);

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate));
  };

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{props.t("Welcome Back !")}</h5>
                        <p>{props.t("Sign in to continue to RIDDER TITAN.")}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-between">
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span> 
                      </div>
                    </Link>
                    <div className="nav-lang">
                      <LanguageDropdown />
                    </div>
                  </div>
                  <div className="p-2"> 
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">{props.t("Username")}</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder={props.t("Enter Username")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{props.t("Password")}</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder={props.t("Enter Password")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          {props.t("Remember me")}
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          {props.t("Log In")}
                        </button>
                      </div>
 
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          {props.t("Forgot your password?")}
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                {props.t("Don&#39;t have an account ?")}{" "} <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    {props.t("Signup now")}{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} RIDDER TITAN
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object
}

export default withTranslation()(withRouter(Login));
 