import React, {useState, useEffect} from 'react';   
import {post} from '../../helpers/api_helper' 
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
 
import {  
  useNavigate  
} from "react-router-dom";

import {
  Col, 
  FormFeedback, Input, Form 
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import TeamSunViewNode from './team-sun-view-node';
 
const TeamSunView = (props) => {
      
  const navigate = useNavigate();  
  const [ members, setMembers] = useState([]);
  const [ username, setUsername] = useState();
    
  useEffect(() => {  
    userProfile();    
    affiliateList();    
  }, []);

  const userProfile = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser")); 
      const response = await post("/user/profile", {token: obj.token});
 
      if (response.data.code === 3002) {
        navigate('/logout'); 
      } 
 
    } catch (error) {
      console.log(error);
    }
  };
  
  const affiliateList = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser")); 
      const response = await post("/user/teams", {token: obj.token});
 
      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        let data = response.data.data;
        setMembers(data);  
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: { 
      username: username, 
    }, 
    onSubmit: async (values) => { 
      try {  
        setMembers([])

        const obj = JSON.parse(localStorage.getItem("authUser"));
        values.token = obj.token; 
        const response = await post("/user/teams", values); 
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if(response.data.code !== 1){ 
          Notify.failure(response.data.message); 
        } else {  
          let data = response.data.data;
           
          const arrayOfObjects = Object.keys(data).map(key => {
            const obj = data[key];
            obj.key = key; // Add the key as a property to the object
            return obj;
          }); 
          setMembers(arrayOfObjects);  
          
        }
      } catch (error) {  
        console.log(error)
        // Notify.error(error.data.message); 
      }
    }
  });

  useEffect(() => {
    if (validation.values.username === '') {
      affiliateList();
    }
  }, [validation.values.username]);
  
  
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [newNodes, setNewNodes] = useState([]);
  
  
  const showChildNodes = (selectedNodes) => {
    if(selectedNodes.totalSponsor > 0){ 
        if(selectedNodes !== undefined){
          if(selectedNodes.children?.length == 0){
            showChildNodes2(selectedNodes)
          }
        }
      } 
  };

  const showChildNodes2 = async (selectedNodes) => { 
    const obj = JSON.parse(localStorage.getItem("authUser"));
    let values = {};
    values.token = obj.token; 
    values.username = selectedNodes.username; 
    values.type = "downline"; 
    const response = await post("/user/teams", values); 
    
    if (response.data.code !== 1) {
      Notify.failure(props.t(response.data.message)); 
    } else {
      let data = response.data.data;
      const arrayOfObjects = Object.keys(data).map(key => {
        const obj = data[key];
        obj.key = key; // Add the key as a property to the object
        return obj;
      });
      selectedNodes["children"] = arrayOfObjects;
      setNewNodes(prevNewNodes => [...prevNewNodes, selectedNodes]);   
  };
}
 


  return ( 
    <div className="member-chart position-relative" >   
      <Form
          className="row row-cols-lg-auto g-3 mb-3 align-items-center" 
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >  
            <Col xs={12}>
            <label className="visually-hidden" htmlFor="inlineFormInputGroupUsername">{props.t("username")}</label> 
              <Input
                name="username"
                type="text"
                placeholder={props.t("Enter Username")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.username || ""}
                invalid={
                  validation.touched.username && validation.errors.username ? true : false
                }
              />
              {validation.touched.username && validation.errors.username ? (
                <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
              ) : null}
            </Col> 
            <Col xs={12}>
              <button type="submit" className="btn btn-primary w-md">{props.t("Search")}</button>
              <span className="btn btn-secondary ml-1 w-md"  onClick={() => validation.setFieldValue("username", "")}>{props.t("Reset")}</span>
            </Col>
      </Form>
          
      <SimpleBar className="pt-3" data-simplebar="init" forceVisible="y" autoHide={false} style={{ maxHeight: "500px" }}>
        <TeamSunViewNode initialData={members}  showChildNodes={showChildNodes} /> 
      </SimpleBar>
    </div>  
  );
};
 
export default withTranslation()(TeamSunView);

TeamSunView.propTypes = {
  t: PropTypes.any
};
