import CryptoJS from "crypto-js";

import md5 from 'md5';
import json  from 'json-keys-sort';  
  
const signKey = process.env.REACT_APP_SIGNKEY;  
const secretKEY = process.env.REACT_APP_AESKEY;  
const secretIV = process.env.REACT_APP_AESIV;  
 

export const encryptData = (postJson, suffix) => {
    let IV1 = secretIV + suffix;
    let IV = CryptoJS.enc.Utf8.parse(IV1);
    let key1 = CryptoJS.enc.Utf8.parse(secretKEY); 
    let postdata = JSON.stringify(postJson);  
    let encrypted = CryptoJS.AES.encrypt(postdata, key1, { iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString(); 
};


export const decryptData = (postdata, suffix) => {
    let IV1 = secretIV+suffix
    let IV = CryptoJS.enc.Utf8.parse(IV1);
    let key1 = CryptoJS.enc.Utf8.parse(secretKEY);  
    let decrypted = CryptoJS.AES.decrypt(postdata, key1, { iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }); 
    return decrypted.toString(CryptoJS.enc.Utf8)
};

export const genSign = (postJson) => { 
    var sign = ""; 
    postJson = json.sort(postJson, true) 
    Object.entries(postJson).forEach((entry) => {
        const [key, value] = entry; 
        sign += key+"="+value+"&"
    });
    
    sign = sign + signKey;  
    return md5(sign)
} 
 
export const logs = (data, type) => { 
    if(import.meta.env.REACT_APP_ENV === 'dev')
    {
        if(type){
            logs(type)
        }
        logs(data)
    }
} 
 
export const parseMessage = (returnString, params) => {
    let formattedString = returnString;
  
    // Replace placeholders in the returnString with corresponding parameter values
    for (const [key, value] of Object.entries(params)) {
      formattedString = formattedString.replace(`%${key}`, value);
    }
  
    return formattedString;
  }
 
export const consoleLog = (error) => {
    console.log(error)
}
