import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {post} from '../../helpers/api_helper' 
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";
 
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from "reactstrap";

import Spinners from "../../components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "../../components/Common/TableContainer";
 
import { Notify } from 'notiflix/build/notiflix-notify-aio';

const DepositList = (props) => {
  //meta title
  document.title = props.t("Deposit Records")+" | RIDDER TITAN";

  const [isLoading, setLoading] = useState(true) 
  const [packageData, setPackageData] = useState();

  const packageList = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/user/depositHistory", {token: obj.token});

      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else { 
        if(response.data?.data){ 
          let listdata = response.data.data;  
          setPackageData(listdata);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    packageList(); // Call the async function immediately
  }, []); // Empty dependency array to run the effect only once
 
  const columns = useMemo(
    () => [ 
      {
        header: props.t("Order Number"),
        accessorKey: "order_number",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return ( 
              <span className="text-muted mb-0">{cellProps.row.original.order_number}</span> 
          );
        }
      },
      {
        header: props.t("Amount"),
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: props.t("Approve Amount"),
        accessorKey: "approve_amount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      
      {
        header: props.t("Status"),
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const original = cellProps.row.original;
          return (
            <React.Fragment>
              <span className={`text-${original.status} mb-0`}>{props.t(original.status)}</span> 
              {(cellProps.row.original.status_code === 2 && cellProps.row.original.remark !== null) && ( 
                <div><small>({props.t(cellProps.row.original.remark)})</small></div>
              )}
            </React.Fragment>
          );
        }, 
      }, 
    ],
    []
  );

  return (
    <React.Fragment> 
      <div className="page-content">
        <Container fluid>
          
          <Row>
            <Col lg="12">
              <div>
                {
                  isLoading ? <Spinners setLoading={setLoading} />
                    :
                    <Row>
                      <Col lg={12}>
                        <Card> 
                          <CardBody className="border-bottom">
                              <div className="d-flex align-items-center">
                                  <h5 className="mb-0 card-title flex-grow-1">{props.t("Deposit Records")}</h5>
                                  <div className="flex-shrink-0">
                                      <Link to="/deposit"className="btn btn-primary me-1">{props.t("Deposit")}</Link> 
                                  </div>
                              </div>
                          </CardBody>
 
                          <CardBody>
                            <TableContainer
                              columns={columns}
                              data={packageData?.data || []}
                              isGlobalFilter={true}
                              isAddButton={false}
                              isPagination={true}
                              isCustomPageSize={false} 
                              SearchPlaceholder={props.t("9 records...")}
                              buttonClass="btn btn-success btn-rounded" 
                              tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                              theadClass="table-light"
                              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                              pagination="pagination"
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                }
              </div>
            </Col>
          </Row>
 
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}; 
export default withTranslation()(DepositList);

DepositList.propTypes = {
  t: PropTypes.any
};