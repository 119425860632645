import React, { useEffect } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap"
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";


const StockChart = (props) => {
    const { data } = props;
 
    return ( 
        <React.Fragment>
            <Row>
                <Col xl="4"> 
                <Card>
                    <CardBody> 
                    <ul className="list-group">
                        <li className="list-group-item"> <h5>{props.t("share_price")}: ${data.stockInfo?.price}</h5> </li>
                        <li className="list-group-item"><h5>{props.t("rtgc_price")}: ${data.rtgc_price}</h5> </li> 
                    </ul> 
                    </CardBody>
                    </Card>
                </Col>
                <Col xl="8"> 
                    <Card style={{ height: '450px' }}>
                        <AdvancedRealTimeChart 
                            interval="D" 
                            symbol="RTGC" 
                            theme="light" 
                        autosize>
                        </AdvancedRealTimeChart>  
                    </Card> 
                </Col>
            </Row>
        </React.Fragment>
    );
}; 
export default withTranslation()(StockChart);

StockChart.propTypes = {
  t: PropTypes.any
}; 
