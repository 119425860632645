import React, {useState, useEffect, useRef } from 'react';  
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";
  
import { Notify } from 'notiflix/build/notiflix-notify-aio';


const TeamSunViewNode =  (props) => {
  const {initialData, showChildNodes} = props; 

  const [teamData, setTeamData] = useState(initialData);
  
    // Update data when initialData prop changes
    useEffect(() => {
      setTeamData(initialData);
    }, [initialData]);

    const [isExpanded, setIsExpanded] = useState(true);
  
    const [items, setItems] = useState([]);
    const toggleItem = (item) => {
      if (items.includes(item)) {
        // If item is already in the array, remove it
        setItems(items.filter((i) => i !== item));
      } else {
        // If item is not in the array, add it
        setItems([...items, item]);
      } 
    };
    
    const handleToggle = (index) => { 
      toggleItem(index) 
    };
        
    const copyToClipboard  = (code) => { 
      // Create a new text area element
      const textArea = document.createElement('textarea');
      textArea.value = code;

      // Append the text area to the DOM
      document.body.appendChild(textArea);

      // Select and copy the text from the text area
      textArea.select();
      document.execCommand('copy');

      // Clean up
      document.body.removeChild(textArea);
  
      Notify.success(props.t("Invite code have copy to clipboard"));   
  }; 
  
  const clickFunction = (node) => { 
    if(node.children?.length == 0){
      showChildNodes(node); // Call the parent function with a parameter
      toggleItem(node.id) 
    } else { 
      handleToggle(node.id)
    }
  };

    return (
      <>
      {teamData?.length > 0 && (
        teamData.map((node, index) => ( 
          <div className="tree-node" key={index}>
            <div className={`node-toggle ${isExpanded ? 'expanded' : ''}`}>
            <span className="tree-pref">|-</span> 
              {node.totalSponsor > 0 ? (
                <span  onClick={() => clickFunction(node)}>
                  {items.includes(node.id)  ? <i className="far fa-minus-square"></i> : <i className="far fa-plus-square"></i>}
                </span>
              ) : ("")}
              <span className="tree-list"></span> 
              <span className="tree-list-info">
                <span className="username">{node.username}</span>
                <span className="ml-1 invite_code" onClick={() => copyToClipboard(node.referral_code) }  >{node.referral_code} 
                    <i className="far fa-copy ml-1"></i>
                </span><br/>
                <span className="email">({node.email})</span> 
              </span>
            </div>
  
            {node.children?.length > 0 && items.includes(node.id)  &&(
              <ul className="child-nodes">
                <li>
                  <TeamSunViewNode initialData={node.children}  t={props.t} showChildNodes={showChildNodes} />
                </li> 
              </ul> 
            )}
          </div>  
        ))
      )}
    </>
    );
}; 
  
export default withTranslation()(TeamSunViewNode);

TeamSunViewNode.propTypes = {
  t: PropTypes.any
};