import React, { useEffect, useMemo, useState } from "react";
import {  useNavigate} from "react-router-dom";

import {post} from '../../helpers/api_helper' 
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';
 
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from "reactstrap";


import Spinners from "../../components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "../../components/Common/TableContainer";
 
const ReserveList = (props) => {
  //meta title
  document.title = props.t("Reserve Records")+" | RIDDER TITAN";

  const [isLoading, setLoading] = useState(true) 
  const [listData, setListData] = useState();
 
  const reserveList = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/reserveHistory", {token: obj.token});

      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else { 
        if(response.data.data?.data){
          let listdata = response.data.data.data;  
          setListData(listdata);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    reserveList(); // Call the async function immediately
  }, []); // Empty dependency array to run the effect only once
 
  const columns = useMemo(
    () => [ 
      {
        header: props.t("Transaction Date"),
        accessorKey: "datetime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: props.t("Order Number"),
        accessorKey: "order_number",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return ( 
              <span className="text-muted mb-0">{cellProps.row.original.order_number}</span> 
          );
        }
      },
      {
        header: props.t("Share Value"),
        accessorKey: "share_value",
        enableColumnFilter: false,
        enableSorting: true,
      }, 
      {
        header: props.t("Share Price"),
        accessorKey: "share_price",
        enableColumnFilter: false,
        enableSorting: true,
      }, 
      {
        header: props.t("Status"),
        accessorKey: "status",
        cell: (cellProps) => {
            return ( 
                <span className={`text-${cellProps.row.original.status} mb-0`}>{props.t(cellProps.row.original.status)}</span> 
            );
        }, // Added comma here
        enableColumnFilter: false,
        enableSorting: true,
    }, 
    ],
    []
  );

  return (
    <React.Fragment> 
      <div className="page-content">
        <Container fluid>
          
          <Row>
            <Col lg="12">
              <div>
                {
                  isLoading ? <Spinners setLoading={setLoading} />
                    :
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <TableContainer
                              columns={columns}
                              data={listData || []}
                              isGlobalFilter={true}
                              isAddButton={false}
                              isPagination={true}
                              isCustomPageSize={false} 
                              SearchPlaceholder={props.t("9 records...")}
                              buttonClass="btn btn-success btn-rounded" 
                              tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                              theadClass="table-light"
                              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                              pagination="pagination"
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                }
              </div>
            </Col>
          </Row>
 
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}; 
export default withTranslation()(ReserveList);

ReserveList.propTypes = {
  t: PropTypes.any
};