import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link , useNavigate} from "react-router-dom";
import moment from 'moment';

import {post} from '../../helpers/api_helper' 
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";

import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';

import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  UncontrolledTooltip,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Images
import companies01 from "../../assets/images/companies/img-1.png";

import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_blue.css";
 
//Import Component
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
 
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import Spinners from "../../components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "../../components/Common/TableContainer";
 
const SellList = (props) => {
  //meta title
  document.title = props.t("Sell Records")+" | RIDDER TITAN";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [isLoading, setLoading] = useState(true) 
  const [listData, setListData] = useState();
 
  const sellList = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/sellHistory", {token: obj.token});

      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        if(response.data.data?.data){
          let listdata = response.data.data.data;  
          setListData(listdata);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sellList(); // Call the async function immediately
  }, []); // Empty dependency array to run the effect only once
 
  const columns = useMemo(
    () => [ 
      {
        header: props.t("Transaction Date"),
        accessorKey: "datetime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: props.t("Order Number"),
        accessorKey: "order_number",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return ( 
              <span className="text-muted mb-0">{cellProps.row.original.order_number}</span> 
          );
        }
      },
      {
        header: props.t("Queue Qty"),
        accessorKey: "queue_qty",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: props.t("Queue Price"),
        accessorKey: "queue_price",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: props.t("Match Qty"),
        accessorKey: "match_qty",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: props.t("Match Price"),
        accessorKey: "match_price",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: props.t("Status"),
        accessorKey: "status",
        cell: (cellProps) => {
            return ( 
                <span className={`text-${cellProps.row.original.status} mb-0`}>{props.t(cellProps.row.original.status)}</span> 
            );
        }, // Added comma here
        enableColumnFilter: false,
        enableSorting: true,
    }, 
    ],
    []
  );

  return (
    <React.Fragment> 
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Sell Records")} breadcrumbItem={props.t("Wallet")} />
          <Row>
            <Col lg="12">
              <div>
                {
                  isLoading ? <Spinners setLoading={setLoading} />
                    :
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <TableContainer
                              columns={columns}
                              data={listData || []}
                              isGlobalFilter={true}
                              isAddButton={false}
                              isPagination={false}
                              isCustomPageSize={false} 
                              SearchPlaceholder={props.t("9 records...")}
                              buttonClass="btn btn-success btn-rounded" 
                              tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                              theadClass="table-light"
                              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                              pagination="pagination"
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                }
              </div>
            </Col>
          </Row>
 
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}; 
export default withTranslation()(SellList);

SellList.propTypes = {
  t: PropTypes.any
};