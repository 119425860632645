import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  InputGroup,
  Label,
  Input,
  Form,
  FormGroup,
  FormFeedback
} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import "react-datepicker/dist/react-datepicker.css";

import { Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'; 
 
import { withTranslation } from "react-i18next";
import {post} from '../../helpers/api_helper' 

//Dropzone
import Dropzone from "react-dropzone";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';

const CryptoKYCApplication = props => {

  //meta title
  document.title=props.t("KYC Application")+" | RIDDER TITAN";
 
  const navigate = useNavigate(); 
  const [selectedFiles, setSelectedFiles] = useState([]); 
  const [base64Image, setBase64Image] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false); 
  const [userdata, setUserdata] = useState([]);
  const [mustkyc, setMustkyc] = useState(false);

  useEffect(() => {
    const userProfile = async () => {
      try {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const response = await post("/user/profile", {token: obj.token});
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if (response.data.code !== 1) {
          Notify.failure(props.t(response.data.message)); 
        } else {
          let userdata = response.data.data;
          setUserdata(userdata);
          if(userdata.is_kyc !== 0){
            navigate('/dashboard'); 
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    userProfile(); // Call the async function immediately
  }, []); // Empty dependency array to run the effect only once

  const handleAcceptedFiles = async (files) => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
    
    const file = files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result;
      setBase64Image(base64Data);   
    };

    reader.readAsDataURL(file);
  };
 
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: '',
      lastname: '',
      phone: '',
      dob: '',
      city: 'Malaysia'
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required(props.t("Please Enter Your firstname")), 
      lastname: Yup.string().required(props.t("Please Enter Your lastname")), 
      phone: Yup.string().required(props.t("Please Enter Your phone")), 
      dob: Yup.string().required(props.t("Please Enter Your dob")), 
      city: Yup.string().required(props.t("Please Select Your City"))
    }),
    onSubmit: async (values) => { 
      try {  
        const obj = JSON.parse(localStorage.getItem("authUser"));
        values.upload = base64Image;
        values.token = obj.token;
        const response = await post("/user/kyc", values); 
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if(response.data.code !== 1){
          console.log("error") 
          Notify.failure(response.data.message); 
        } else { 
          navigate('/dashboard'); 
        }
      } catch (error) {  
        console.log(error)
        // Notify.failure(error.data.message); 
      }
    }, 
  });

  const handleDateSelect = (date) => {
    validation.setFieldValue('dob', date[0]); // Update Formik's 'dob' field value
    validation.setFieldTouched('dob', true); // Mark 'dob' field as touched for validation
  };

  useEffect(() => { 
    if (
      validation.values.firstname &&
      validation.values.lastname &&
      validation.values.phone &&
      validation.values.dob &&
      validation.values.city &&
      base64Image
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    } 
  }, [validation.values, base64Image]);
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
    
          <Row className="justify-content-center">
            <Col>
              <Card>
                <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0 card-title flex-grow-1">{props.t("KYC Verification")}</h5>
                        <div className="flex-shrink-0">
                            <Link to="/profile"className="btn btn-primary me-1">{props.t("Profile")}</Link> 
                        </div>
                    </div>
                </CardBody>  
                <CardBody>
                <Row className="justify-content-center">
                  <Col xl="5" sm="9">
                    <div className="text-center">
                      <Row>
                        <Col>
                          <h4 className="mt-4 fw-semibold">
                          {props.t("KYC Verification")}
                          </h4>
                          <p className="text-muted mt-3">
                          {props.t("Verify your identity for secure access to enhanced features and benefits.")}
                          </p>
                        </Col>
                      </Row> 
                    </div> 

                    <div className="justify-content-start"> 
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                        <div id="kyc-verify-wizard" className="wizard clearfix"> 
                          <div className="content clearfix"> 
                            <Row>
                              <Col lg="12">
                              <h4 className="mt-1">
                                  <span className="number">1.</span>
                                  {props.t("Personal Info")}
                                </h4>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="kycfirstname-input"
                                    className="form-label"
                                  >
                                    {props.t("First name")}
                                  </Label>
                                  <Input
                                    name="firstname"
                                    className="form-control"
                                    type="text" 
                                    id="firstname-input"
                                    placeholder={props.t("Enter First name")}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.firstname || ""}
                                  />
                                  {validation.touched.firstname && validation.errors.firstname ? (
                                    <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="kyclastname-input"
                                    className="form-label"
                                  >
                                    {props.t("Last name")}
                                  </Label>
                                  <Input
                                    name="lastname"
                                    type="text"
                                    className="form-control"
                                    id="kyclastname-input"
                                    placeholder={props.t("Enter Last name")}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lastname || ""}
                                  />
                                  {validation.touched.lastname && validation.errors.lastname ? (
                                    <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row> 
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="kycphoneno-input"
                                    className="form-label"
                                  >
                                    {props.t("Phone")}
                                  </Label>
                                  <Input
                                    name="phone"
                                    type="text"
                                    className="form-control"
                                    id="kycphoneno-input"
                                    placeholder={props.t("Enter Phone number")}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone || ""}
                                  />
                                  {validation.touched.phone && validation.errors.phone ? (
                                    <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="kycbirthdate-input"
                                    className="form-label"
                                  >
                                    {props.t("Date of birth")}
                                  </Label>

                                  <InputGroup>
                                    <Flatpickr
                                      name="dob"
                                      className="form-control d-block"
                                      placeholder="dd M,yyyy"
                                      options={{
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        dateFormat: "Y-m-d"
                                      }}
                                      onChange={handleDateSelect}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.dob || ""}
                                    />
                                  </InputGroup> 
                                  {validation.touched.dob && validation.errors.dob ? (
                                    <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                              <FormGroup className="mb-3">
                                  <Label
                                    htmlFor="kycselectcity-input"
                                    className="form-label"
                                  >
                                    {props.t("City")}
                                  </Label>
                                  <select
                                    name="city"
                                    className="form-select"
                                    id="kycselectcity-input"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.city} // Set the value attribute
                                  >
                                    <option value="Malaysia">{props.t("Malaysia")}</option>
                                    <option value="Singapore">{props.t("Singapore")}</option>
                                    <option value="China">{props.t("China")}</option>
                                    <option value="San Francisco">{props.t("San Francisco")}</option>
                                    <option value="Los Angeles">{props.t("Los Angeles")}</option>
                                    <option value="San Diego">{props.t("San Diego")}</option>
                                  </select>
                                </FormGroup>
                              </Col>
                            </Row> 
                            <Row>
                              <Col lg="12">
                                <h4 className="mt-1">
                                  <span className="number">2.</span>
                                  {props.t("Document Verification")}
                                </h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <h5 className="font-size-14 mb-3">
                                {props.t("Upload document file for a verification")}
                                </h5>
                                <div className="kyc-doc-verification mb-3">
                                  <Dropzone
                                    onDrop={acceptedFiles =>
                                      handleAcceptedFiles(acceptedFiles)
                                    }
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="mb-3">
                                            <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                          </div>
                                          <h4>
                                          {props.t("Drop files here or click to upload.")}
                                          </h4>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews mt-3"
                                    id="file-previews"
                                  >
                                    {selectedFiles.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                              </Col>
                                              <Col>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>
                                                    {f.formattedSize}
                                                  </strong>
                                                </p>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                </div>
                              </Col>
                            </Row>  
                          </div>

                          <div className="row justify-content-center"> 
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                                disabled={!canSubmit}
                                >
                                {props.t("Submit")}
                              </button> 
                          </div>  
                        </div>
                        </Form>
                    </div> 
                  </Col>
                </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}; 
export default withTranslation()(CryptoKYCApplication);

CryptoKYCApplication.propTypes = {
  t: PropTypes.any
};