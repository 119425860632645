import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next"; 

import { Notify } from 'notiflix/build/notiflix-notify-aio';


import {post} from '../../helpers/api_helper' 
 
import {
    Button,
    Modal, 
    FormGroup,
    Form,
    Input, 
    InputGroup,
    Label,
} from "reactstrap";

const propTypes = {
  nodeData: PropTypes.object.isRequired
};

const AffiliateOrgchartNode = (props) => {
    const { nodeData, callFunction } = props;
 
            
    const [token, setToken] = useState("");   
    const [ position, setPosition ] = useState("");  
    const [ username, setUsername ] = useState("");  
    const [ email, setEmail] = useState("");  
    const [ invite_code, setInvite_code ] = useState("");  
    const [ upline_id, setUpline_id ] = useState(0);  
    const [ upline_username , setUpline_username  ] = useState("");  
    const [ pwd, setPwd ] = useState("");  
    const [ pwdConfirm, setPwdConfirm ] = useState("");   
    const [ isDisabled, setIsDisabled] = useState(true);

    const [ showPs, setShowPs] = useState(false); 
    const [ formModal, setFormModal] = useState(false);  
    
    const addDownline = (upline_id, username, position) => { 
        setUsername("")  
        setEmail("")  
        setPwd("")  
        setPwdConfirm("")   
        setPosition(position)   
        setUpline_id(upline_id)   
        setUpline_username(username)   
        setInvite_code("") 
        setFormModal(true)  
    };
    
    const toggleModal = state => {
        setFormModal(!state) 
    };

    const toggleShowPs = () => {
        setShowPs((showPs) => !showPs); 
    };
    
    const inputChangeHandler = (setFunction, event) => {
        const { name, value, type, checked } = event.target;
        
        // Check if the input is a checkbox
        if (type === 'checkbox') {
            setFunction(checked);
        } else {
            // For other input types
            setFunction(value);
        }

        if (type === 'checkbox' && !checked) {
            setFunction(false);
        }
    };
    
    useEffect(() => {  
        setIsDisabled(username === '' || email === '' || invite_code === '' || pwd === '' || pwdConfirm === ''); 

    }, [username, email, invite_code, pwd, pwdConfirm, token]);
    
    const handleAddDownline = async () => {    
        if(pwd !== pwdConfirm){ 
            Notify.failure(props.t('Password not match'));
            return false;
        }
        
        const obj = JSON.parse(localStorage.getItem("authUser"));
        let userdata = { 
        username: username,
        email: email,
        leader_id: obj.member_id,
        upline_id: upline_id,
        position: position,
        invite_code: invite_code,
        pwd: pwd,
        pwdConfirm: pwdConfirm,
        token: obj.token
        } 
        try { 
        const response = await post("/user/addDownline", userdata);
        
        if(response.data.code === 3001 || response.data.code === 3002 )
        { 
            Notify.failure(props.t(response.data.message)); 
            localStorage.removeItem('token'); 
            localStorage.removeItem('userdata');   
            navigate('/login');
        }  

        if(response.data.code !== 1){ 
            Notify.failure(props.t(response.data.message));  
        } else {
                
            Notify.success(props.t('Success. Member was added in network'));
            setFormModal(false)  
            window.location.reload();
        }
        } catch (error) {  
        console.log(error)
        // Notify.error(error.data.message); 
        } 
    };
        
    const copyToClipboard  = (code) => { 
        // Create a new text area element
        const textArea = document.createElement('textarea');
        textArea.value = code;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select and copy the text from the text area
        textArea.select();
        document.execCommand('copy');

        // Clean up
        document.body.removeChild(textArea);
    
        Notify.success(props.t("Invite code have copy to clipboard"));   
    }; 
    return ( 
        <>
            <div className="member-card"> 
                <div className="member-card-header">  
                    <div>
                        <h4>{nodeData.username}</h4>   
                        <span onClick={() => copyToClipboard(nodeData.referral_code) } className="invite_code">{nodeData.referral_code} 
                            <i className="far fa-copy ml-1"></i>
                        </span>  
                    </div>
                </div>
                <div className="member-card-info">
                <div className="member-card-row">
                    <div className="member-card-col">
                    <h4>{props.t("Total Package")}</h4>
                    </div>
                    <div className="member-card-col">
                    <h4>{nodeData.totalPackage}</h4>
                    </div>
                </div>
                <div className="member-card-row"> 
                    <div className="member-card-col">
                    <h4>{props.t("Total Team")}</h4>
                    </div>
                    <div className="member-card-col">
                    <h4>{nodeData.totalMember}</h4>
                    </div>
                </div>
                <div className="member-card-row">
                    <div className="member-card-col">
                    <h4>{props.t("Direct Sponser")}</h4>
                    </div>
                    <div className="member-card-col">
                    <h4>{nodeData.totalDirect}</h4>
                    </div>
                </div>
                <div className="member-card-row">
                    <div className="member-card-col">
                    <h4>{props.t("Total Sale")}</h4>
                    </div>
                    <div className="member-card-col">
                    <h4>{nodeData.totalBigSales}</h4>
                    </div>
                </div>
                <div className="member-card-row">
                    <div className="member-card-col">
                    <h4>{props.t("total_sales_left")}</h4>
                    </div>
                    <div className="member-card-col">
                    <h4>{nodeData.totalLeftSales}</h4>
                    </div>
                </div>
                <div className="member-card-row">
                    <div className="member-card-col">
                    <h4>{props.t("total_sales_right")}</h4>
                    </div>
                    <div className="member-card-col">
                    <h4>{nodeData.totalRightSales}</h4>
                    </div>
                </div>
                <div className="member-card-row">
                    <div className="member-card-col">
                    <h4>{props.t("Position")}</h4>
                    </div>
                    <div className="member-card-col">
                    <h4>{props.t(nodeData.position)}</h4>
                    </div>
                </div>

                </div>
                
                {nodeData.totalChild !== 2 && ( 
                <div className="member-card-footer">
                    {nodeData.haveLeft === 0 && (
                    
                    <span className="btn btn-primary btn-sm" onClick={() => addDownline(nodeData.id,nodeData.username, "L")}>{props.t("Add Member(L)")}</span>
                    )}
                    {nodeData.haveRight === 0 && (
                    <span className="btn btn-secondary btn-sm" onClick={() => addDownline(nodeData.id,nodeData.username, "R")}>{props.t("Add Member(R)")}</span>
                    )}
                </div>  
                )} 
            </div> 
            {nodeData.totalChild > 0 && (
                <i className="btn-downline fas fa-plus"></i>
            )}
            <Modal
                className="modal-dialog-centered"
                isOpen={formModal}
                toggle={() => toggleModal("formModal")}
            >
                <div className="modal-header">
                <h5 className="modal-title">
                {props.t("Add Downline")}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleModal("exampleModal")}
                >
                    <span aria-hidden={true}>×</span>
                </button>
                </div>
                <div className="modal-body"> 
                <Form role="form" autoComplete="off"> 
                    <Input  
                        type="hidden"
                        name="invite_code"
                        value={invite_code}
                        disabled={true}  
                    /> 
                    <FormGroup>
                        <Label className="form-label">{props.t("placement ID")}</Label>
                        <Input 
                        placeholder={props.t("Username")}   
                        value={upline_username} 
                        disabled={true}
                        />  
                    </FormGroup>
                    <FormGroup>
                        <Label className="form-label">{props.t("Username")}</Label>
                        <Input 
                        placeholder={props.t("Username")}  
                        name="username"
                        value={username}
                        onChange={(e)=>inputChangeHandler(setUsername, e)}     
                        autoComplete='off'
                        />  
                    </FormGroup>

                    <FormGroup>
                        <Label className="form-label">{props.t("Email")}</Label>
                        <Input 
                        placeholder={props.t("Email")}
                        type="email" 
                        name="email"
                        value={email}
                        onChange={(e)=>inputChangeHandler(setEmail, e)}    
                        />  
                    </FormGroup>
                    
                    <FormGroup> 
                    <Label className="form-label">{props.t("Password")}</Label>
                    <InputGroup>
                        <Input
                            placeholder={props.t("Password")}
                            type={showPs ? 'text' : 'password'}
                            autoComplete="off"
                            value={pwd}
                            onChange={(e)=>inputChangeHandler(setPwd, e)}    
                        />
                        <div className="input-group-append"  onClick={toggleShowPs}> 
                            <span className="input-group-text">
                            {showPs ?(
                                    <i className="fas fa-eye"></i>
                                ): (
                                <i className="fas fa-eye-slash"></i> 
                            )}
                            </span>
                        </div>
                    </InputGroup>    
                    </FormGroup> 

                    <FormGroup>  
                    <Label className="form-label">{props.t("Password Confirmation")}</Label>
                    <InputGroup>
                        <Input
                            placeholder={props.t("Password Confirmation")}
                            type={showPs ? 'text' : 'password'}
                            autoComplete="off"
                            value={pwdConfirm}
                            onChange={(e)=>inputChangeHandler(setPwdConfirm, e)}    
                        /> 
                        <div className="input-group-append"  onClick={toggleShowPs}> 
                        <span className="input-group-text">
                            {showPs ?(
                                <i className="fas fa-eye"></i>
                            ): (
                                <i className="fas fa-eye-slash"></i> 
                            )}
                            </span>
                        </div>
                    </InputGroup>   
                    </FormGroup>  
                    
                    <FormGroup>
                    <Label className="form-label">{props.t("Invite Code")}</Label> 
                    <Input 
                        placeholder={props.t("Invite Code")}
                        name="invite_code" 
                        autoComplete="off"
                        value={invite_code}
                        onChange={(e)=>inputChangeHandler(setInvite_code, e)}   
                    /> 
                    </FormGroup>

                    <FormGroup>
                    <Label className="form-label">{props.t("Position")}</Label> 
                    <Input  
                        name="position"
                        value={position}
                        disabled={true}  
                    /> 
                    </FormGroup>

                    <div className="text-center">
                    <Button
                        className="mt-4"
                        color="primary" 
                        disabled={isDisabled}  
                        onClick={handleAddDownline}  
                    >
                        {props.t("Add Downline")}
                    </Button>
                    </div>
                </Form>
                
                </div>
            </Modal>  
        </>
    );
}; 
export default withTranslation()(AffiliateOrgchartNode);

AffiliateOrgchartNode.propTypes = {
  t: PropTypes.any
};