import React, { useState, useEffect } from 'react';
import moment from 'moment';

const CountdownTimer = ({ id, endDate, t  }) => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = moment();
      const diff = moment(endDate).diff(now);
      const duration = moment.duration(diff);
      setCountdown(`${duration.days()} ${t('D')} ${duration.hours()} ${t('H')} ${duration.minutes()} ${t('M')} ${duration.seconds()} ${t('S')}`);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endDate]);

  return <h5 id={id}>{t("Countdown")}:   <span className="text-bold">{countdown}</span></h5>;
};

export default CountdownTimer;